import { startsWith } from 'lodash';
import { userService } from './userService/UserService';

const disabledRoutes = [
  'ClientSchedule',
  'ClientNotifications',
  'AdminSchedule',
  'AccountManagerSchedule',
  'AccountManagerNotifications',
  'QASchedule',
];

export class MixPanelService {
  constructor(app) {
    this.app = app;
  }
  trackViewed(to, from) {
    if (to.name) {
      if (disabledRoutes.includes(to.name) || startsWith(to.name, 'Client')) {
        return;
      }

      this.trackEvent(
        `${to.name}Viewed`,
        this.getParamsForViewed(to, from)
      );
    }
  }
  trackEvent(eventName, parameters, app = this.app) {
    const body = {
      environment: import.meta.env.VITE_APP_ENV.split('-')[0].toUpperCase(),
      environment_full: import.meta.env.VITE_APP_ENV,
    };

    app.$mixpanel.track(eventName, { ...body, ...parameters });
  }
  getParamsForViewed(to, from = null) {
    let params = {
      distinct_id: userService.userData.id,
      $current_url: window.location.origin + to.path,
      roles: userService.userData.roles,
    };

    // Add source param
    if (
      [
        'ClientPPDReport',
        'AdminPPDReport',
        'ClientBillingOverview',
      ].includes(to.name)
      && from
    ) {
      params = { ...params, ...this.#getSourceParams(from.name) };
    }

    const trackingMixPanelParams = {
      utm_skaid: 'UTM Skaid',
    };

    const urlSearchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlSearchParams.entries()) {
      if (
        key in trackingMixPanelParams
        && !Object.keys(params)
          .includes(key)
        && !!value.trim()
      ) {
        params[trackingMixPanelParams[key]] = value;
      }
    }

    return params;
  }
  #getSourceParams(routeName) {
    let source;

    switch (routeName) {
      case null:
        source = 'Link';
        break;
      case 'ClientDashboard':
        source = 'Dashboard';
        break;
      default:
        source = 'NavigationMenu';
    }

    return {
      source,
    };
  }
}

export const mixPanelService = new MixPanelService();
